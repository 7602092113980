Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpPatchMethod = "PATCH";
exports.httpDeleteMethod = "DELETE";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.changePasswordEndPoint = "account_block/accounts/update_password";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
exports.updateProfileEndPoint = "account_block/accounts/update_profile";
exports.getProfileEndPoint = "account_block/accounts";
exports.deleteImageEndPoint = "account_block/accounts/remove_image";
exports.deleteAccountEndPoint = "account_block/accounts/remove_user";
exports.getRecentSearchEndPoint = "bx_block_landingpage2/landing_pages/recent_searches";
exports.postRecentSearchMsgEndPoint = "bx_block_landingpage2/support_analysts";
exports.deleteRemoveListENdPoint = "bx_block_landingpage2/landing_pages/remove_from_list"
// Customizable Area End
