export const landingBackImage = require("../assets/landing_backimage.png");
export const searchIcon = require("../assets/searchIcon.svg");
export const uploadIcon = require("../assets/uploadIcon.svg");
export const dropdown = require("../assets/dropdown.svg");
export const rowBlack = require("../assets/rowBlack.svg");
export const rowDark = require("../assets/rowDark.svg");
export const plusIcon = require("../assets/plusIcon.svg");
export const avtarLogo = require("../assets/avtarLogo.svg");
export const assigneeLogo = require("../assets/assigneeLogo.svg");
export const checkedIcon = require("../assets/checkedIcon.svg");
export const uncheckedIcon = require("../assets/uncheckedIcon.svg");
export const historyIcon = require("../assets/historyIcon.svg");
export const rightArrow = require("../assets/right-arrow.svg");
export const joinIcon = require("../assets/joinIcon.svg");
export const groupTop = require("../assets/group_top.png");
export const arrowIcon = require("../assets/arrowIcon.svg");
export const roundRightArrow = require("../assets/roundRightArrow.svg");
export const roundLeftArrow = require("../assets/roundLeftArrow.svg");
export const correctArrowIcon = require("../assets/correctArrowIcon.svg");
export const closeIcon = require("../assets/closeIcon.svg");
export const uploadDoc = require("../assets/uploadDoc.svg");
export const browserPlusIcon = require("../assets/browserPlusIcon.svg");
export const textPage = require("../assets/textPage.svg");
export const keyExpend = require("../assets/keyExpend.svg");
export const keyFeatureImg1 = require("../assets/keyFeatureImg1.png");
export const keyFeatureImg2 = require("../assets/keyFeatureImg2.png");
export const keyFeatureImg3 = require("../assets/keyFeatureImg3.png");
export const keyFeatureImg4 = require("../assets/keyFeatureImg4.png");
export const keyFeatureImg5 = require("../assets/keyFeatureImg5.png");
export const keyFeatureImg6 = require("../assets/keyFeatureImg6.png");
export const download = require("../assets/download.svg");
export const showMappig = require("../assets/showMappig.svg");
export const overlapping = require("../assets/overlapping.svg");
export const document = require("../assets/document.svg");
export const whiteCalender = require("../assets/whiteCalender.svg");
export const leftArrow = require("../assets/leftArrow.svg");
export const listView = require("../assets/listView.svg");
export const genaretingDownload = require("../assets/genaretingDownload.svg");
export const downArrow = require("../assets/downArrow.svg");
export const search = require("../assets/search.svg");
export const downArrowWhite = require("../assets/downArrowWhite.svg");
export const filterIcon = require("../assets/filterIcon.svg");
export const save = require("../assets/save.svg");
export const placeHolderImage = require("../assets/y9DpT.jpg")