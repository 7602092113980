//@ts-nocheck
import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Paper,
  // Customizable Area End
  Grid,
} from "@mui/material";

// Customizable Area Start
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import DownloadOptionsController, {
  Props,
} from "./DownloadOptionsController.web";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default class DownloadOptions extends DownloadOptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth={"sm"}>
            <h3 style={{ textAlign: "center" }}>Download Images</h3>
            <Paper elevation={4}>
              {this.state.downloadData.map(
                (element: { [keysData: string]: string }, index: number) => (
                  <div key={index} style={{ padding: "10px" }}>
                    <h6 style={{ textTransform: "capitalize" }}>
                      {index + 1}. {element.title}
                    </h6>
                    <Grid container spacing={2}>
                      <Grid
                        xs={12}
                        sm={8}
                        md={8}
                        item
                        className="downloadButton"
                      >
                        <img
                          data-test-id="downloadImageSection"
                          src={element.value}
                          alt={element.altVal}
                          height="200px"
                          width="auto"
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        sm={4}
                        md={4}
                        item
                        className="downloadButton"
                      >
                        <Button
                          data-test-id="downloadDataButton"
                          disabled={!element.value}
                          variant="contained"
                          onClick={() =>
                            this.handleDownload(element.value, element.title)
                          }
                        >
                          Download
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                )
              )}
            </Paper>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
