import React from "react";
// Customizable Area Start
import Footer from "../../../components/src/footer.web";
import { closeIcon, keyExpend, download, leftArrow, listView, genaretingDownload, document, whiteCalender } from "./assets";
import Header from "../../../components/src/Header.web";
import { Box, Dialog, Grid, IconButton, styled } from "@material-ui/core";
import Loader from "../../../components/src/Loader.web";
import {Link} from "react-router-dom"
// Customizable Area End

import PublicationSimilarDocumentController, {
  Props,
} from "./PublicationSimilarDocumentController";

export default class PublicationSimilarDocument extends PublicationSimilarDocumentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  gridViewFunction = () => {
    const scholarData = this.state.similarDocData.filter((doc: { scholar_id: string }) => doc.scholar_id);
    return (
      <Grid container spacing={3}>
        {scholarData?.map((item, index) => (
          <Grid item key={index} xs={this.state.girdView ? 6 : 12}>
            <Box className={this.state.girdView ? "publication-white-container-gridView" : "publication-white-container"}>
              <Box className="publication-main-text">
                {item?.title}
              </Box>
              <Box className="publication-Id-btn">
                <Box className="publication-Id-btn-text">
                  {item?.scholar_id}
                </Box>
              </Box>
              <Box className="publication-buttons-main-box">
                <Box className="publication-buttons-box">
                  <img src={document} alt="document" />
                  <Box className="publication-button-text">
                    View Documents
                  </Box>
                </Box>
                <Link to={`/ClassificationLandingPage/scholar/${item?.scholar_id}`} className="a-class">
                  <Box id="descriptionPageGrid" className="publication-buttons-box-red">
                    <img src={whiteCalender} alt="whiteCalender" />
                    <Box className="publication-button-text-white" id="classification-page">
                      View More
                    </Box>
                  </Box>
                </Link>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    )
  };
  /* istanbul ignore next */
  handleNavigation = () => {
    const { selectResult, loading, similarDocData } = this.state;
    const hasPatentResults = similarDocData?.length > 0;
    return (
      <Box>
        {selectResult === 'Publications' && !loading && (
          hasPatentResults ? (
            this.gridViewFunction()
          ) : (
            <Box component="div" className="no-result-text">No Results Found</Box>
          )
        )}
        
      </Box>
    );
  }  
  
  // Customizable Area End

  render() {    
    return (
      <>
        <Loader loading={this.state.loading} />
        <Header title={""} />
        <KeyFeatureLandingPageContainerCss>
          <Grid container>
            <Box 
            className="back-arrowimain-box"
            >
              <Box 
              className="back-arrow-box"
              >
                <img src={leftArrow} alt="leftArrow" onClick={() => this.goBackToLandingPage()} className="back-arrow-img" id="navigate-landingPage"  />
                <Box  id="heading" className="back-arrow-text" >
                  View Key Features
                  </Box>
              </Box>
            </Box>
          </Grid>
          <Box 
          className="result-Main"
          >
            <Box>
              <Box className="caseId-main">  Case ID : <Box component="span" className="box-span">001</Box>
              </Box>
              <Box className="top-ten-result">Showing top 10 results</Box>
              <Box className="keyfeature-box">
                <Grid item xs={12} 
                className="keyFeature-search-grid"
                >
                  <Box 
                  className="keyFeature-search-box-looking"
                  >
                    {this.state.selectedTabs.map((item: string, index: number) => (
                      <Box 
                      className={item !== this.state.selectResult ? "keyFeature-box-search-loop" : "keyFeature-box-search-loop-select"}
                        id={`statusChange${index}`} 
                        onClick={() => this.onClickSetStutus(item)}
                        >
                        <Box 
                        key={index} 
                        className="keyFeature-box-name">
                          {item}
                          </Box>
                      </Box>
                    ))}
                  </Box>
                </Grid>
                <Box 
                className="download-Report-main">
                  <Box className="report-buttons-box"  onClick={() => this.downloadModelOpen()} id="downloadOpen">
                    <img 
                    src={download} 
                    alt="download"
                     />
                    <Box className="report-button-text">Download Report</Box>
                  </Box>
                  <Box className="keyFeature-img-box">
                    <img 
                    className="listView-style" 
                    src={this.state.girdView ? listView : keyExpend} 
                    id="listView" 
                    alt="keyExpend" 
                    onClick={() => this.handleClickGridView()} />
                  </Box>
                </Box>
                <ReportDialog
                  aria-labelledby="customized-dialog-title"
                  open={this.state.downReportModel}
                  id="downloadClose"
                  fullWidth
                  PaperProps={{
                    elevation: 0,
                    className: "download-modal",
                  }}
                  BackdropProps={{className: "backdrop-modal"}}
                >
                  <Box 
                  className="download-closeIconStyle"
                  >
                    <IconButton 
                    aria-label="close"
                    >
                      <img
                        src={closeIcon}
                        alt="closeIcon"
                        id="download-Click-Close"
                        width={24}
                        height={24}
                        onClick={() => this.downloadModelClose()}
                      />
                    </IconButton>
                  </Box>
                  <Box 
                  className="down-box"
                  >
                    <Box className="download-main-box">
                      <img src={genaretingDownload} alt="genaretingDownload" className="download-Icon-style"/>
                      <Box>
                        <Box className="download-title-model">We are generating your report,</Box>
                        <Box className="download-model-Text"> Once completed you can Download it from your Profile Settings</Box>
                      </Box>
                    </Box>
                  </Box>
                </ReportDialog>
              </Box>
            </Box>
           {this.handleNavigation()}
          </Box>
        </KeyFeatureLandingPageContainerCss>
        <Footer />
      </>
    );
  }
}

// Customizable Area Start
const ReportDialog = styled(Dialog)({
  "& .download-modal": {
    borderRadius: 0,
    background: '#F5F5F4',
    height: 298,
    maxWidth: 738,
  },
  "& .backdrop-modal": {
    backgroundColor: 'rgba(15, 23, 42, 0.4)'
  },
  "& .download-main-box": {
    alignItems: "center",
    display: "flex",
    textAlign: "center",
    gap: 30,
    flexDirection: "column",
  },
  "& .download-Icon-style": {
    cursor: 'pointer',
  },
  "& .download-title-model": {
    fontFamily: 'Inter',
    marginBottom: "15px",
    fontSize: 20,
    fontWeight: 700,
    color: '#4F5356',
  },
  "& .download-model-Text": {
    fontSize: 20,
    fontWeight: 700,
    color: '#4F5356',
    fontFamily: 'Inter',
  },
  "& .down-box": {
    padding: 60,
    height: "100%",
  },
  "& .download-closeIconStyle": {
    top: 8,
    right: 10,
    position: 'absolute',
  },
});

const KeyFeatureLandingPageContainerCss = styled(Box)({
  "& .back-arrowimain-box": {
    padding: "30px 0px 20px 108px",
    background: "#E8E7E7",
    "@media(max-width:600px)": {
      padding: "40px 20px",
    },
    "@media(max-width:1280px)": {
      padding: "40px 40px"
    },
    "@media(max-width:960px)": {
      padding: "40px 20px"
    },
  },
  "& .back-arrow-box": {
    display: "flex",
    gap: 16,
    alignItems: "center",
  },
  "& .back-arrow-img": {
    cursor: "pointer"
  },
  "& .back-arrow-text": {
    color: "#212324",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
  },
  "& .result-Main": {
    padding: "30px 108px 20px",
    background: "#E8E7E7",
    "@media(max-width:600px)": {
      padding: "40px 20px",
    },
    "@media(max-width:1280px)": {
      padding: "40px 40px"
    },

    "@media(max-width:960px)": {
      padding: "40px 20px"
    },
  },
  "& .keyFeature-white-container": {
    background: "#F5F5F4",
    padding: "32px 24px",
    borderRadius: 8,
  },
  "& .no-result-text": {
    fontSize: 24,
    display: "flex",
    fontFamily: "Inter",
    alignItems: "center",
    height: "200px",
    fontWeight: 700,
    justifyContent: "center",
  },
  "& .keyFeature-white-container-gridView": {
    padding: "32px 16px",
    borderRadius: 8,
    background: "#F5F5F4",
    minHeight:"939px",
  },
  "& .keyFeature-text-main": {
    fontSize: 24,
    fontWeight: 700,
    marginBottom: 24,
    fontFamily: "Inter",
  },
  "& .keyFeature-main-gridView-text": {
    fontWeight: 700,
    fontFamily: "Inter",
    fontSize: 24,
    marginTop: 24
  },
  "& .box-span": {
    color: "#DF4833"
  },
  "& .keyFeature-img-main": {
    padding: "24px",
    background: "#F0F0F0",
    flexWrap: "wrap",
    height: 172,
    gap: 24,
    overflowY: "scroll",
    display: "flex",
  },
  "& .keyFeature-img-main-gridView": {
    flexWrap: "wrap",
    height: 172,
    display: "flex",
    overflowY: "scroll",
    gap: 24,
    padding: "0 10px",
  },
  "& .img-loop": {
    height: 172,
    objectFit: "contain",
    width: 166,
  },
  "& .img-class": {
    width: 166,
    height: 172,
  },
  "& .keyFeature-btn-Id": {
    fontFamily: "Inter",
    fontWeight: 700,
    color: "#DF4833",
    background: "#FCEDEB",
    fontSize: 24,
    width: "fit-content",
    textTransform: "none",
    marginTop: 24,
    height: 58,
    padding: "0px 24px",
    borderRadius: 8,
    display: "flex",
    border: 'none',
    gap: 8,
    alignItems: "center",
  },
  "& .keyFeature-gridView-Id-btn": {
    color: "#DF4833",
    fontFamily: "Inter",
    fontWeight: 700,
    display: "flex",
    width: "fit-content",
    background: "#FCEDEB",
    borderRadius: 8,
    border: 'none',
    gap: 8,
    fontSize: 24,
    padding: "0px 24px",
    margin: "24px 0",
    alignItems: "center",
    textTransform: "none",
    height: 58,
  },
  "& .keyFeature-p-text": {
    fontWeight: 400,
    marginTop: 10,
    color: "#4F5356",
    fontSize: 16,
    marginBottom: 24,
    fontFamily: "Inter",
  },
  "& .keyFeature-box-search-loop": {
    padding: '0 16px',
    display: 'flex',
    background: "#F1F5F9",
    borderRadius: 4,
    alignItems: 'center',
    height: 46,
    width: "fit-content",
    cursor: "pointer",
    "@media(max-width:550px)": {
      width: "100%",
    },
  },
  "& .keyFeature-gridView": {
    marginTop: 30
  },
  "& .keyFeature-box-search-loop-select": {
    width: "fit-content",
    borderRadius: 4,
    display: 'flex',
    height: 46,
    cursor: "pointer",
    alignItems: 'center',
    background: "#FCEDEB",
    padding: '0 16px',
    "@media(max-width:550px)": {
      width: "100%",
    },
  },
  "& .keyFeature-search-box-looking": {
    gap: 10,
    display: "flex",
    "@media(max-width:550px)": {
      flexWrap: "wrap",
    },
  },
  "& .keyFeature-search-grid": {
    justifyContent: 'space-between',
    display: "flex",
  },
  "& .keyFeature-box-name": {
    fontFamily: "Inter",
    fontSize: 14,
    fontWeight: 400,
    color: "#64748B",
  },
  "& .top-ten-result": {
    fontSize: 16,
    color: "#DF4833",
    marginBottom: 5,
    fontWeight: 400,
    fontFamily: "Inter",
  },
  "& .caseId-main": {
    fontFamily: "Inter",
    color: "#4F5356",
    fontSize: 24,
    marginBottom: 30,
    fontWeight: 700,
  },
  "& .keyfeature-box": {
    marginBottom: 30,
    display: 'flex',
    "@media(max-width:830px)": {
      flexWrap: "wrap",
    },
  },
  "& .download-Report-main": {
    display: 'flex',
    alignItems: 'center',
    gap: 24,
    "@media(max-width:830px)": {
      marginTop: 30
    },
  },
  "& .keyFeature-img-box": {
    borderRadius: 8,
    background: "#F1F5F9",
    padding: 10,
  },
  "& .listView-style": {
    cursor: "pointer"
  },
  "& .keyFeature-box-chart": {
    gap: 32,
    display: 'flex',
    "@media(max-width:900px)": {
      flexDirection: "column",
    },
  },
  "& .keyFeature-box-gridView-chart": {
    gap: 10,
    display: 'flex',
  },
  "& .keyFeature-main-chart-box": {
    width: "35%",
    borderRadius: 8,
    height: "274px",
    padding: "24px",
    background: "#fff",
    "@media(max-width:900px)": {
      width: "94%"
    },
    "@media(max-width:700px)": {
      width: "92%"
    },
    "@media(max-width:500px)": {
      width: "89%"
    },
    "@media(max-width:400px)": {
      width: "85%"
    },
  },
  "& .keyFeature-chart-box-gridView-main": {
    height: "274px",
    padding: "10px",
    background: "#fff",
    width: "50%",
    borderRadius: 8,
  },
  "& .keyFeature-Overlapping-main-box": {
    padding: "24px",
    background: "#fff",
    height: "274px",
    width: "35%",
    borderRadius: 8,
    "@media(max-width:900px)": {
      width: "94%"
    },
    "@media(max-width:700px)": {
      width: "92%"
    },
    "@media(max-width:500px)": {
      width: "89%"
    },
    "@media(max-width:400px)": {
      width: "85%"
    },
  },
  "& .keyFeature-Overlapping-main-box-gridView": {
    padding: "10px",
    borderRadius: 8,
    width: "50%",
    height: "274px",
    background: "#fff",
  },
  "& .KeyFeature-progressStyle": {
    borderRadius: "10px",
    width: '70%',
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#DF4833"
    }
  },
  "& .overlapping-Label": {
    marginBottom: "5px",
    fontSize: 14,
    color: "#0F172A",
    fontWeight: 400,
    fontFamily: "Inter",
  },
  "& .overlapping-percentage": {
    fontWeight: 700,
    fontSize: "12px",
    color: "#0F172A",
    fontFamily: "Inter",
  },
  "& .overlapping-head-Box": {
    justifyContent: "space-between",
    display: 'flex',
  },
  "& .overlapping-border": {
    margin: "20px 0",
    background: "#E2E8F0",
    width: "100%",
    height: "1px",
  },
  "& .overlapping-text": {
    fontSize: "12px",
    color: "#0F172A",
    fontWeight: 400,
    fontFamily: "Inter",
  },
  "& .percent-text": {
    color: "#64748B",
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: 400,
  },
  "& .overlapping-box-container": {
    marginBottom: 25
  },
  "& .overlapping-Label-percentage": {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  "& .buttons-main-box": {
    flexWrap: "wrap",
    marginTop: 24,
    gap: 20,
    display: "flex",
  },
  "& .buttons-box": {
    cursor: "pointer",
    display: "flex",
    borderRadius: "8px",
    padding: "10px 16px",
    alignItems: "center",
    background: "#F5F5F4",
    border: "1px solid #AEB0B1",
    gap: "10px",
  },
  "& .link-text": {
    textDecoration: "none"
  },
  "& .buttons-box-red": {
    padding: "10px 16px",
    gap: "10px",
    background: "#DF4833",
    display: "flex",
    borderRadius: "8px",
    alignItems: "center",
    cursor: "pointer",
  },
  "& .button-text": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#4F5356",
    fontFamily: "Inter",
  },
  "& .button-text-white": {
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Inter",
    color: "#FFFFFF",
  },
  "& .report-buttons-box": {
    alignItems: "center",
    display: "flex",
    gap: "10px",
    cursor: "pointer",
    width: "200px",
    padding: "10px 0px 10px 16px",
    borderRadius: "8px",
    background: "#EFF6FF",
  },
  "& .report-button-text": {
    fontSize: "16px",
    color: "#3B82F6",
    fontWeight: 700,
    fontFamily: "Inter",
  },
  "& .publication-white-container": {
    background: "#F5F5F4",
    borderRadius: 8,
    maxHeight: "878px",
    overflowY: "scroll",
    padding: "32px 24px",
  },
  "& .publication-white-container-gridView": {
    background: "#F5F5F4",
    borderRadius: 8,
    maxHeight: "939px",
    overflowY: "scroll",
    padding: "32px 16px",
  },
  "& .publication-main-text": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "24px",
    marginBottompx: "24px"
  },
  "& .publication-Id-btn": {
    alignItems: "center",
    display: "flex",
    width: "fit-content",
    height: "58px",
    justifyContent: "center",
    padding: "0px 24px",
    background: "#FCEDEB",
    marginTop: "24px",
    borderRadius: 8,
  },
  "& .publication-Id-btn-text": {
    fontFamily: "Inter",
    color: "#DF4833",
    fontSize: 24,
    textTransform: "none",
    fontWeight: 700,
  },
  "& .text-box-main": {
    marginTop: 24,
  },
  "& .publication-text-bold": {
    fontSize: "20px",
    fontFamily: "Inter",
    marginBottom: "7px",
    fontWeight: 700,
    lineHeight: "28px",
    color: "#212324",
  },
  "& .publication-p-text": {
    fontSize: 18,
    color: "#4F5356",
    fontWeight: 400,
    fontFamily: "Inter",
    marginBottom: "5px 0 15px 0",
    lineHeight: "28px",
  },
  "& .publication-small-text": {
    fontSize: 16,
    color: "#727578",
    cursor: "pointer",
    fontWeight: 400,
    width: "100%",
    lineHeight: "28px",
    margin: "15px 0",
    fontFamily: "Inter",
  },
  "& .a-class": {
    color: "#727578",
    textDecoration: "none",
    wordBreak: "break-all",
    cursor: "pointer",
  },
  "& .publication-li-text": {
    fontSize: 20,
    color: "#4F5356",
    lineHeight: "28px",
    fontFamily: "Inter",
    fontWeight: 400,
  },
  "& .publication-li-text-red": {
    fontSize: 20,
    color: "#DF4833",
    lineHeight: "28px",
    fontWeight: 400,
    fontFamily: "Inter",
  },
  "& .publication-buttons-main-box": {
    gap: 20,
    marginTop: 24,
    display: "flex",
    flexWrap: "wrap",
  },
  "& .publication-buttons-box": {
    display: "flex",
    cursor: "pointer",
    gap: "10px",
    border: "1px solid #AEB0B1",
    borderRadius: "8px",
    padding: "10px 16px",
    alignItems: "center",
    background: "#F5F5F4",
  },
  "& .publication-buttons-box-red": {
    gap: "10px",
    padding: "10px 16px",
    borderRadius: "8px",
    alignItems: "center",
    background: "#DF4833",
    display: "flex",
    cursor: "pointer",
  },
  "& .publication-button-text": {
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Inter",
    color: "#4F5356",
  },
  "& .publication-button-text-white": {
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Inter",
  },
});

// Customizable Area End
