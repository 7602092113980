import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";


interface PatentSimilarDoc {
  is_scholar: boolean;
  scholar_id: string;
  scholar_authors: string;
  publication_date: string;
  title: string;
  patent_id: string;
  serpapi_link: string;
  publication_number: string
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedTabs:  string[];
  girdView: boolean;
  selectResult: string;
  downReportModel: boolean;
  loading: boolean;
  similarDocData: PatentSimilarDoc[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PublicationSimilarDocumentController extends BlockComponent<
  Props,
  S,
  SS
> {
    apiGetPublicationDetailsCallId : string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationKeyFeatureMessage),
    ];

    this.state = {
      loading: false,
      selectedTabs: [
        "Publications",
      ],
      selectResult:"Publications",
      similarDocData: [],
      girdView: false,
      downReportModel: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

      if (responseJson.errors?.length) {
        this.setState({
          loading: false
        })
      }
      const tokenExpire = responseJson.errors?.[0]?.token
      if (tokenExpire) {
        this.goingToLogInPage()
      }
     if (apiRequestCallId === this.apiGetPublicationDetailsCallId) {
        if(responseJson?.similar_documents?.length !== 0) {
        this.setState({similarDocData :responseJson?.similar_documents, loading: false})
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const loginToken = await getStorageData("token")
    if(!loginToken){
      this.goingToLogInPage()
    }
    const publicationId = this.props.navigation.getParam("id")  
    if(publicationId){
      this.getPublicationDetailsAPI(publicationId)
    }
  }

  goBackToLandingPage = async () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );

    message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "KeyFeatureLandingPageWeb"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage),
    );

    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);

  };

  handleClickGridView = () => {
    this.setState(prevState => ({ girdView: !prevState.girdView}));
  };

  onClickSetStutus = (value:string) => {
    this.setState({selectResult:value})
  }

  downloadModelOpen = () => {
    this.setState({ downReportModel: true })
  };

  downloadModelClose = () => {

    this.setState({ downReportModel: false })

  };

  goToShowMappingPage = async () => {

    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPageShowMapping"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );

    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);

  };

  goingToLogInPage = async () => {
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );

    messageLogin.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );

    messageLogin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );

    messageLogin.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(messageLogin);
  };

  getPublicationDetailsAPI = async (publicationId: string) => {
    this.setState({ loading: true })
    const header = {
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPublicationDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPublicationDetailsAPIEndPoint}?scholar_id=scholar/${publicationId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
