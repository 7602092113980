import React from "react";

// Customizable Area Start
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  styled,
} from "@material-ui/core";
import { checkedIcon, uncheckedIcon } from "./assets";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        {!this.state.termsResponse?.document?.url && !this.state.termsResponse?.link && this.props.modelType === "Terms" && <TermAndConditionDialog
          open={this.props.openTermAndConditionModel}
          onClose={this.props.handleCloseTAndCModel}
          id="terms-onClose-model"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            className: "success-modal",
            elevation: 0
          }}
          BackdropProps={{
            className: "backdrop-modal",
          }}
        >
          <DialogTitle className="dailog-HeadingText" id="dailog-Heading">{"Terms & Conditions"}</DialogTitle>
          <DialogContent>
              <DialogContentText
                className="dailog-contentText1"
                id="alert-dialog-description"
                dangerouslySetInnerHTML={{ __html: this.state.termsData }}
              >
              </DialogContentText>
            <Grid container>
              <Grid item xs={12} className="logIn-agree-checkbox">
                <Checkbox checkedIcon={
                  <img
                    src={checkedIcon}
                    alt="barcodeIp"
                  />
                } icon={
                  <img
                    src={uncheckedIcon}
                    alt="barcodeIp"
                  />
                } color="primary" className="logIn-checkbox-label"
                  checked={this.props.formValTerms}
                  id="checkBox-terms"
                  onChange={(event) => this.props.handleChangeTerms(event)}
                />
                <Box className="logIn-Remember">I Agree with <Box component="span" className="LogInCreateAcc-dif" id="open-privacy-model" onClick={() => this.props.hendleOpenTAndCModel("Privacy")}>Privacy Policy</Box> and <Box component="span" className="LogInCreateAcc-dif" id="open-TAndC-model" onClick={() => this.props.hendleOpenTAndCModel("Terms")}>Terms & Conditions</Box></Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={3} justifyContent="flex-end">
              <Grid item>
                <button className="cancleBtn" id="terms-model-close" onClick={() => this.props.handleCloseTAndCModel()}>
                  Cancel
                </button>
              </Grid>
              <Grid item>
                <button className={this.props.formValTerms ? "acceptBtn" : "acceptBtn-disable"} disabled={!this.props.formValTerms} id="terms-accept" onClick={() => this.props.handleCloseTAndCModel()}>
                  Accept
                </button>
              </Grid>
            </Grid>
          </DialogActions>
        </TermAndConditionDialog>}
        {this.props.modelType === "Privacy" && <TermAndConditionDialog
          onClose={this.props.handleCloseTAndCModel}
          open={this.props.openTermAndConditionModel}
          aria-describedby="alert-dialog-description"
          BackdropProps={{
            className: "backdrop-modal",
          }}
          aria-labelledby="alert-dialog-title"
          PaperProps={{
            elevation: 0,
            className: "success-modal",
          }}
          id="terms-onClose-model"
        >
          <DialogTitle id="dailog-Heading" className="dailog-HeadingText">{"Privacy Policy"}</DialogTitle>
          <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                className="dailog-contentText1"
                dangerouslySetInnerHTML={{ __html: this.state.privacyPolicyData }}
              >
              </DialogContentText>
            <Grid container>
              <Grid className="logIn-agree-checkbox" item xs={12} >
                <Checkbox checkedIcon={
                  <img
                    alt="barcodeIp"
                    src={checkedIcon}
                  />
                } icon={
                  <img
                    alt="barcodeIp"
                    src={uncheckedIcon}
                  />
                }
                  className="logIn-checkbox-label"
                  onChange={(event) => this.props.handleChangeTerms(event)}
                  color="primary"
                  id="checkBox-terms"
                  checked={this.props.formValTerms}
                />
                <Box className="logIn-Remember">I Agree with <Box className="LogInCreateAcc-dif" component="span" onClick={() => this.props.hendleOpenTAndCModel("Privacy")} id="open-privacy-model">Privacy Policy</Box> and <Box className="LogInCreateAcc-dif" component="span" onClick={() => this.props.hendleOpenTAndCModel("Terms")} id="open-TAndC-model">Terms & Conditions</Box></Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="flex-end" spacing={3}>
              <Grid item>
                <button id="terms-model-close" onClick={() => this.props.handleCloseTAndCModel()} className="cancleBtn">
                  Cancel
                </button>
              </Grid>
              <Grid item>
                <button disabled={!this.props.formValTerms} className={this.props.formValTerms ? "acceptBtn" : "acceptBtn-disable"} onClick={() => this.props.handleCloseTAndCModel()} id="terms-accept">
                  Accept
                </button>
              </Grid>
            </Grid>
          </DialogActions>
        </TermAndConditionDialog>}
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const TermAndConditionDialog = styled(Dialog)({
  "& .MuiDialogTitle-root": {
    borderBottom: "1px solid #CBD5E1",
    padding: "24px 40px",
  },
  "& .MuiDialogContent-root": {
    padding: "40px",
  },
  "& .MuiDialogActions-root": {
    borderTop: "1px solid #CBD5E1",
    padding: "24px",
  },
  "& .success-modal": {
    borderRadius: 8,
    maxWidth: 600,
    background: '#F5F5F4',
  },
  "& .backdrop-modal": {
    backgroundColor: 'rgba(15, 23, 42, 0.4)'
  },
  "& .closeIconStyle": {
    top: 0,
    padding: 14,
    right: 0,
    position: 'absolute',
  },
  "& .acceptBtn": {
    fontWeight: 700,
    fontStyle: "normal",
    color: "#FFF",
    width: 150,
    borderRadius: "8px",
    fontFamily: "Inter",
    height: 56,
    background: "#EF4444",
    gap: "8px",
    padding: "10px 16px",
    textTransform: "none",
    cursor: "pointer",
    fontSize: "16px",
    border: 'none',
  },
  "& .acceptBtn-disable": {
    color: "#FFF",
    background: "#E87171",
    height: 56,
    fontSize: "16px",
    gap: "8px",
    borderRadius: "8px",
    textTransform: "none",
    cursor: "pointer",
    width: 150,
    padding: "10px 16px",
    fontWeight: 700,
    fontFamily: "Inter",
    fontStyle: "normal",
    border: 'none',
  },
  "& .cancleBtn": {
    color: "#DF4833",
    fontSize: "16px",
    fontFamily: "Inter",
    border: 'none',
    fontWeight: 700,
    textTransform: "none",
    height: 56,
    cursor: "pointer",
    width: 150,
    fontStyle: "normal",
    padding: "10px 16px",
    borderRadius: "8px",
    background: "#FCEDEB",
    gap: "8px",
  },
  "& .FormTextField": {
    borderRadius: "10px",
    "& .MuiOutlinedInput-notchedOutline": {
      top: -6,
      borderRadius: "8px",
    },
    "& .MuiInputBase-root": {
      fontFamily: "Inter"
    },
    '& .Mui-focused fieldset': {
      borderColor: '#CBD5E1',
    },
    "& .MuiFormHelperText-contained": {
      margin: 0
    }
  },
  "& .dailog-HeadingText": {
    '& .MuiTypography-h6': {
      fontFamily: "Inter",
      color: "#4F5356",
      fontWeight: 700,
      fontSize: "24px",
      "@media (max-width: 524px)": {
        textAlign: "center"
      },
    },
  },
  "& .dailog-contentText1": {
    fontSize: "16px",
    fontFamily: "Inter",
    color: "#4F5356",
    fontWeight: 400,
  },
  "& .logIn-remember-check": {
    height: 25,
    alignItems: "center",
    display: "flex",
  },
  "& .logIn-agree-checkbox": {
    height: 15,
    alignItems: 'center',
    marginTop: 32,
    display: 'flex',
  },
  "& .logIn-Remember": {
    fontFamily: "Montserrat",
    color: "#4F5356",
    fontWeight: 400,
    fontSize: "14px",
  },
  "& .LogInCreateAcc-dif": {
    lineHeight: "24px",
    color: "#DF4833",
    fontSize: "14px",
    cursor: "pointer",
    fontStyle: "normal",
    fontWeight: 700,
    fontFamily: "Montserrat",
  },
  "& .logIn-checkbox-label": {
    paddingRight: 8,
    padding: 0,
  },
})
// Customizable Area End
