import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Startundefined
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  viewMoreClassification: boolean;
  loading:boolean
  publicationDetails: {
    search_parameters: {
        patent_id: string
    },
    title: string,
    publication_year: string,
    full_view_url: string,
    authors: string[],
    classifications: [
        {
            code: string,
            description: string,
        }
    ],
    snippet: string
}
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ClassificationPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetPublicationDetailsCallId: string = ""
  apiGetDetailsCallId: string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationKeyFeatureMessage),
    ];

    this.state = {
      viewMoreClassification: false,
      loading: false,
      publicationDetails:{
        search_parameters: {
          patent_id: ""
        },
        title: "",
        publication_year: "",
        full_view_url: "",
        authors: [],
        classifications: [
          {
            code: "",
            description: "",
          }
        ],
        snippet: ""
      }
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.apiGetPublicationDetailsCallId) {
      if(responseJson) {        
        this.setState({publicationDetails: responseJson, loading: false})
      } 
      if(responseJson?.errors?.length) {
        this.setState({loading: false})
      }
    } 
    if(apiRequestCallId === this .apiGetDetailsCallId) {
      if(responseJson) {
        this.setState({publicationDetails: responseJson,loading: false})
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const BackToDetails = await getStorageData("BackToDetails")
    if(BackToDetails) {
      this.getDetailsAPI()
    } else {
      const publicationId = this.props.navigation.getParam("id")
      setStorageData("publicationId", publicationId)    
      this.getPublicationDetailsAPI(publicationId)
    }
  }
  goBackToPublicationPage = async () => {
    removeStorageData("BackToDetails")
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "KeyFeatureLandingPageWeb"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  getPublicationDetailsAPI = async (publicationId: string) => {
    this.setState({ loading: true })
    const header = {
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPublicationDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPublicationDetailsAPIEndPoint}?scholar_id=scholar/${publicationId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDetailsAPI = async () => {
    this.setState({ loading: true })
    const header = {
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDetailsPublicationAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  viewMoreClassificationFun = () => {
    this.setState({ viewMoreClassification: true })
  };

  hideMoreClassificationFun = () => {
    this.setState({ viewMoreClassification: false })
  };

  goToShowMappingPage = async () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "PublicationShowMapping"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };
  // Customizable Area End
}
