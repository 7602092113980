import { Box, styled } from '@material-ui/core';
import { getStorageData } from '../../framework/src/Utilities';
import React, { Component } from 'react';

interface MyComponentProps {
    // Add props here
    title?: string
}

// Define the state interface
interface MyComponentState {
    // Add state here
    footerDetails: {
        contactEmail: string;
        contactPhone: string;
        contactAddress: string;
    }
}

class Footer extends Component<MyComponentProps, MyComponentState> {
    // Initialize state
    constructor(props: MyComponentProps) {
        super(props);
        this.state = {
            // Add state here
            footerDetails: {
                contactEmail: "",
                contactPhone: "",
                contactAddress: "",
            }
        };
    }

    async componentDidMount() {
        const storageData = await getStorageData("contactData")
        const parseData = JSON.parse(storageData);
        this.setState({
            footerDetails: parseData
        });
    }

    render() {
        return (
            <FooterCss>
                <Box className='footerFirstContainer'>
                    <Box className='footer-main-box'>
                        <Box className='footer-logo-box'>
                            <img className='footerLogoStyle' src={require("./headerLogo.svg")} alt='headerLogo' />
                        </Box>
                        <Box className='footer-containt-box'>
                            <Box className='footer-heading-Text'>
                                Contact us
                            </Box>
                            <Box className='footer-text'>
                                {this.state.footerDetails?.contactPhone}
                            </Box>
                        </Box>
                        <Box className='footer-containt-box'>
                            <Box className='footer-heading-Text'>
                                Email
                            </Box>
                            <Box className='footer-text'>
                                {this.state.footerDetails?.contactEmail}
                            </Box>
                        </Box>
                        <Box className='footer-containt-box'>
                            <Box className='footer-heading-Text'>
                                Address
                            </Box>
                            <Box className='footer-text'>
                                {this.state.footerDetails?.contactAddress}
                            </Box>
                        </Box>
                    </Box>
                    <Box className='footer-divider'></Box>
                    <Box className='footer-copyRight-box'>
                        <Box className='footer-copy-text'>© 2023 — Copyright</Box>
                        <Box className='footer-privacy-text'>Privacy</Box>
                    </Box>
                </Box>
            </FooterCss>
        );
    }
}

const FooterCss = styled(Box)({
    '& .footerFirstContainer': {
        background: '#E8E7E7',
        padding: '20px 108px 20px',
        "@media(max-width:1280px)": {
            padding: "40px 40px",
        },
        "@media(max-width:960px)": {
            padding: "40px 20px",
        },
        "@media(max-width:600px)": {
            padding: "40px 20px",
        },
    },
    "& .footer-main-box": {
        display: 'flex',
        justifyContent: "space-between",
        "@media(max-width:900px)": {
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: "20px",
        },
    },
    "& .footer-logo-box": {
        display: "flex",
        alignItems: "center"
    },
    "& .footerLogoStyle": {
        display: "flex",
        width: "200px",
        height: "29px",
        padding: "0.271px 5px",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    "& .footer-heading-Text": {
        color: "#2B2E2F",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        textTransform: "uppercase",
        marginBottom: 8
    },
    "& .footer-text": {
        color: "#484C4E",
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: 700
    },
    "& .br-tag": {
        marginBottom: 12
    },
    "& .footer-divider": {
        width: "100%",
        height: 1,
        background: "#94A3B8",
        margin: "9px 0 24px 0"
    },
    "& .footer-copyRight-box": {
        display: "flex",
        justifyContent: "space-between"
    },
    "& .footer-copy-text": {
        color: "#212324",
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 400
    },
    "& .footer-privacy-text": {
        color: "#DF4833",
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 400
    },
})

export default Footer;
