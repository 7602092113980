import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData,removeStorageData} from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  changeIcon: boolean;
  changeNewIcon: boolean
  changeConfirmIcon: boolean;
  openModel: boolean;
  formValues: {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
  },
  formErrors: {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
  },
  loading: boolean;
  popupOpen: boolean;
  popUpType: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UserProfilesAccountController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  passwordReg: RegExp;
  apiChangePasswordCallId: string = "";
  apiDeleteAccountCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      changeIcon: false,
      changeNewIcon:false,
      changeConfirmIcon:false,
      openModel: false,
      formValues: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      formErrors: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      loading: false,
      popupOpen: false,
      popUpType: ""
      // Customizable Area End
    };
    // Customizable Area Start
    this.passwordReg = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&*])[A-Za-z\d@#$%^&*]{8,}$/);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const token = await getStorageData("token")
    if(!token){
      this.goToLogInPage()
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiChangePasswordCallId) {
          if (responseJson.message === "Your Password has been successfully Updated") {
            this.setState({
              openModel: true,
              formValues: {
                currentPassword: "",
                newPassword: "",
                confirmPassword: ""
              },
            })
          } else if (responseJson.errors === "old password provided is invalid.") {
            this.setState({
              formErrors: { ...this.state.formErrors, currentPassword: "old password provided is invalid." }
            })
          }
        }
        if(responseJson?.errors[0]?.token){
          this.goToLogInPage()
        }
      }
      this.deleteReciverFunc(apiRequestCallId, responseJson);
    };
    // Customizable Area End
  }

  // Customizable Area Start
  goToLogInPage = async () => {
    removeStorageData("token")
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  onClickShowChangePasswordIcon = () => {
    this.setState({ changeIcon: !this.state.changeIcon })
  }

  onClickShowNewPasswordIcon = () => {
    this.setState({ changeNewIcon: !this.state.changeNewIcon })
  }

  onClickShowConfirmPasswordIcon = () => {
    this.setState({ changeConfirmIcon: !this.state.changeConfirmIcon })
  }

  onClickSaveChanges = () => {
    const { currentPassword, newPassword, confirmPassword } = this.state.formValues;
    const currentPasswordError = this.validateCurrentPass(currentPassword);
    const newPasswordError = this.validateNewPass(newPassword);
    const confirmPasswordError = this.validateConfirmPass(confirmPassword);

    if (!currentPasswordError && !newPasswordError && !confirmPasswordError) {
      this.handleChangePasswordApi();
    }

    this.setState({
      formErrors: {
        currentPassword: currentPasswordError,
        newPassword: newPasswordError,
        confirmPassword: confirmPasswordError
      },
    });
  }

  handleChangePasswordApi = async () => {
    const { currentPassword, newPassword, confirmPassword } = this.state.formValues;
    const headers = {
      token: await getStorageData("token"),
    };

    const formData = new FormData();
    formData.append("old_password", currentPassword);
    formData.append("new_password", newPassword);
    formData.append("confirm_password", confirmPassword);

    const changePasswordMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangePasswordCallId = changePasswordMessage.messageId;

    changePasswordMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordEndPoint
    );

    changePasswordMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    changePasswordMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    changePasswordMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );
    runEngine.sendMessage(changePasswordMessage.id, changePasswordMessage);
  };

  handleModelClose = () => {
    this.setState({
      openModel: false,
    })
  }

  validateCurrentPass = (currentPass: string) => {
    if (!currentPass) {
      return 'Please enter your current password';
    } else {
      return '';
    }
  };

  validateNewPass = (newPass: string) => {
    if (!newPass) {
      return 'Please enter new password';
    } else if (!this.passwordReg.test(newPass)) {
      return 'Please enter valid password';
    } else {
      return '';
    }
  };

  validateConfirmPass = (confirmPass: string) => {
    if (!confirmPass) {
      return 'Please enter confirm password';
    } else if (confirmPass !== this.state.formValues.newPassword) {
      return 'Password does not match';
    } else {
      return '';
    }
  };

  handleChangeCurrentPassword = (event: { target: { value: string; }; }) => {
    const { value } = event.target;
    this.setState((prevState) => ({
      formValues: {
        ...prevState.formValues,
        currentPassword: value,
      },
      formErrors: {
        ...prevState.formErrors,
        currentPassword: this.validateCurrentPass(value)
      }
    }));
  }

  handleChangeNewPassword = (event: { target: { value: string; }; }) => {
    const { value } = event.target;
    this.setState((prevState) => ({
      formValues: {
        ...prevState.formValues,
        newPassword: value
      },
      formErrors: {
        ...prevState.formErrors,
        newPassword: this.validateNewPass(value)
      }
    }));
  }

  handleChangeConfirmPassword = (event: { target: { value: string; }; }) => {
    const { value } = event.target;
    this.setState((prevState) => ({
      formValues: {
        ...prevState.formValues,
        confirmPassword: value
      },
      formErrors: {
        ...prevState.formErrors,
        confirmPassword: this.validateConfirmPass(value)
      }
    }));
  }

  handlePopUpModel = (type: string) => {
    this.setState({
      popUpType: type
    }, () => {
      this.setState({
        popupOpen: true
      })
    })

  }

  handleDeleteAndLogOut = () => {
    if(this.state.popUpType === "delete"){
      this.handleDeleteAccount()

    } else {
      this.handleLogOut()
    }
  }

  handlePopupClose = () => {
    this.setState({
      popupOpen: false
    })
  }

  handleLogOut = () => {
    localStorage.clear()
    this.setState({
      popupOpen: false
    }, () => {
      this.goToLoginPage();
    })
  };

  deleteReciverFunc = (apiRequestCallId: string, responseJson: { message: string; }) => {
    if (apiRequestCallId === this.apiDeleteAccountCallId) {
      if (responseJson?.message === "Account deleted successfully") {
        localStorage.clear()
        this.goToLoginPage();
        this.setState({ loading: false, popupOpen: false })
      } else {
        this.setState({ loading: false })
      }
    }
  };

  handleDeleteAccount = () => {
    this.handleDeleteAccountApi();
  };

  handleDeleteAccountApi = async () => {
    const headers = {
      "Content-Type": "application/json",
      token: await getStorageData("token"),
    };

    this.setState({ loading: true });

    const deleteAccountMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteAccountCallId = deleteAccountMessage.messageId;

    deleteAccountMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAccountEndPoint
    );

    deleteAccountMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    deleteAccountMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );

    runEngine.sendMessage(deleteAccountMessage.id, deleteAccountMessage);
  };

  goToLoginPage = async () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "EmailAccountLoginBlock"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage),
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };
  // Customizable Area End
}
