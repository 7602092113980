import React from "react";
// Customizable Area Start
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  TextField,
  FormControl,
  FormLabel,
} from "@mui/material";
import {
  createTheme,
  Theme,
} from "@mui/material/styles";
import "@mui/styles";
const DatePicker = require("react-datepicker");

import "react-datepicker/dist/react-datepicker.css";
import { profileEdit, uploadIcon, removeIcon, tooltip } from "./assets";
import { Avatar, Box, Grid, ListItemIcon, ListItemText, Menu, MenuItem, MenuProps, Typography, styled, withStyles } from "@material-ui/core";
import Header from "../../../components/src/Header.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

import CustomisableUserProfilesController, {
  Props,
  IField,
} from "./CustomisableUserProfilesController";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  getFieldType = (field: IField) => {
    if (
      field.field_type === "integer" ||
      field.field_type === "number" ||
      field.field_type === "float"
    ) {
      return "number";
    }
    return "text";
  };

  renderField = (field: IField, index: number) => {
    if (
      field.field_type === "text" ||
      field.field_type === "string" ||
      field.field_type === "file" ||
      field.field_type === "textarea" ||
      field.field_type === "number" ||
      field.field_type === "integer" ||
      field.field_type === "float"
    ) {
      return (
        <div key={index + "renderField"}>
          <TextField
            data-test-id={field.name}
            label={field.title}
            multiline={field.field_type === "textarea"}
            value={this.state.profile[field.name]}
            onChange={(event) =>
              this.changeFormValue(
                field.name,
                event.target.value,
                field.field_type
              )
            }
            disabled={!field.is_enable}
            required={field.is_required}
            type={this.getFieldType(field)}
            onKeyPress={(event) => this.onKeyPress(event, field)}
            InputLabelProps={{
              shrink: true,
            }}
            // defaultValue='Enter Value'
          />
        </div>
      );
    }

    if (field.field_type === "date" || field.field_type === "datetime") {
      return (
        <div key={index + "renderField"}>
          <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            {field.title + (field.is_required ? "*" : "")}
          </div>
          <div style={{ marginBottom: "5px", marginTop: "5px" }}>
            <DatePicker
              data-test-id={field.name}
              selected={
                new Date(
                  this.state.profile[field.name] as string
                ).toString() !== "Invalid Date"
                  ? new Date(this.state.profile[field.name] as string)
                  : null
              }
              onChange={(date: Date) =>
                this.changeFormValue(field.name, date, field.field_type)
              }
              disabled={!field.is_enable}
              required={field.is_required}
            />
          </div>
        </div>
      );
    }

    if (field.field_type === "checkbox") {
      return (
        <div key={index + "renderField"}>
          <Checkbox
            data-test-id={field.name}
            disabled={!field.is_enable}
            checked={this.state.profile[field.name] as boolean}
            onChange={(event) =>
              this.changeFormValue(
                field.name,
                event.target.checked,
                field.field_type
              )
            }
            color="primary"
          />
          {field.title}
        </div>
      );
    }
    if (field.field_type === "boolean") {
      return (
        <div key={index + "renderField"}>
          <FormControl>
            <FormLabel>{field.title}</FormLabel>
            <RadioGroup
              row
              data-test-id={field.name}
              onChange={(event) => {
                this.changeFormValue(
                  field.name,
                  event.target.value,
                  field.field_type
                );
              }}
              value={
                this.state.profile[field.name] === undefined
                  ? undefined
                  : String(this.state.profile[field.name])
              }
            >
              <FormControlLabel
                value={"true"}
                control={<Radio />}
                label="True"
              />
              <FormControlLabel
                value={"false"}
                control={<Radio />}
                label="False"
              />
            </RadioGroup>
          </FormControl>
        </div>
      );
    }
  };

  render() {
    // Customizable Area Start
    const { formValues, formErrors } = this.state;
    return (
      <>
        <Loader loading={this.state.loading} />
        <Header
          title={""}
          name={this.state.apiValue.user_name}
          photo={this.state.profileImageUrl}
        />
        <UserProfilePageCss>
          <Grid container justifyContent="center">
            <Grid container spacing={0} item xs={12} className="mainBoxWrap">
              <Grid item xs={12}>
                <Typography className="UserInfoTextStyle" data-test-id="heading" variant="h3">User’s Information</Typography>
              </Grid>
              <Grid container spacing={3} item xs={12}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Box className="profileBoxWrap">
                    <Box className="profileTexts">
                      <Box className="profileImgsBox">
                        <Avatar className='ProfileImgStyle' src={this.state.profileImageUrl ? this.state.profileImageUrl : ""} alt='profileImg' />
                        <img className="ProfileEditStyle" src={profileEdit} alt="profileEdit" id="profile-edit" onClick={this.onClickShow}/>
                      </Box>
                      <StyledMenu
                        id="customized-menu"
                        anchorEl={this.state.editButton}
                        keepMounted
                        open={Boolean(this.state.editButton)}
                        onClose={this.handleMenuClose}
                      >
                        <li className='tooltip-menu'>
                          <img src={tooltip} alt='tooltip' />
                        </li>
                        <StyledMenuItem id="upload-container" onClick={this.handleUpload}>
                          <ListItemIcon>
                            <img src={uploadIcon} alt="profileEdit"/>
                          </ListItemIcon>
                          <ListItemText primary="Upload Photo" id="list-item" />
                          <input type="file" className="uploadPhotoStyle" id="file-input" ref={this.fileInputRef} onChange={this.handleFileChange} />
                        </StyledMenuItem>
                        {this.state.profileImageUrl && 
                        <StyledMenuItem id="list-menu" onClick={this.handleMenuClose}>
                          <ListItemIcon>
                            <img src={removeIcon} alt="profileEdit"/>
                          </ListItemIcon>
                          <ListItemText primary="Remove Photo" id="photo-Remove"  onClick={() => this.removePhoto()} />
                        </StyledMenuItem>}
                      </StyledMenu>
                    </Box>
                    <Box className="profileDetailBox">
                      <Typography className="profileTextName">{this.state.apiValue.user_name}</Typography>
                      <Typography className="profileEmailAndPhoneText">{this.state.apiValue.email}</Typography>
                      <Typography className="profileEmailAndPhoneText">{this.state.apiValue.full_phone_number}</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={4} item xs={12} className="profileTextFielsGrid">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box className="profileTextFields">
                  <Box className="profileTextFieldsLabelBox">
                    <Typography className="lableInput">Name</Typography>
                  </Box>
                  <TextField
                    className="FormTextField"
                    placeholder="Henry Roberts"
                    variant="outlined"
                    id="userName"
                    name="userName"
                    fullWidth
                    required
                    value={formValues.userName}
                    onChange={this.handleProfileNameChange}
                    error={Boolean(formErrors.userName)}
                    helperText={formErrors.userName}
                    inputProps={{
                      className:"FormTextField-placeholder"
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box className="profileTextFields">
                  <Box className="profileTextFieldsLabelBox">
                    <Typography className="lableInput">Email</Typography>
                  </Box>
                  <TextField
                    className="FormTextField"
                    placeholder="Henryroberts26@gmail.com"
                    variant="outlined"
                    name="userEmail"
                    id="userEmail"
                    fullWidth
                    required          
                    value={formValues.userEmail}
                    onChange={this.handleProfileEmailChange}
                    error={Boolean(formErrors.userEmail)}
                    helperText={formErrors.userEmail}
                    inputProps={{
                      className:"FormTextField-placeholder"
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box className="profileTextFields">
                  <Box className="profileTextFieldsLabelBox">
                    <Typography className="lableInput">Phone Number</Typography>
                  </Box>
                  <TextField
                    className="FormTextField"
                    placeholder="+91 99663 35879"
                    variant="outlined"
                    name="userPhone"
                    id="userPhone"
                    fullWidth
                    required
                    value={formValues.userPhone}
                    onChange={this.handleProfilePhoneNumberChange}
                    error={Boolean(formErrors.userPhone)}
                    helperText={formErrors.userPhone}
                    inputProps={{
                      className:"FormTextField-placeholder"
                    }}
                  />
                </Box>
              </Grid>
              <Grid container spacing={5} item xs={12}>
                <Grid item>
                  <button className="profileCancelBtn" id="cancel-button" onClick={this.handleCancelProfile}>
                    Cancel
                  </button>
                </Grid>
                <Grid item>
                  <button className="profileSaveBtn" id="save-button" onClick={this.handleSaveProfile}>
                    Save Changes
                  </button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </UserProfilePageCss>
      </>
      // Customizable Area End
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const UserProfilePageCss = styled(Box)({
  padding: '2% 5%',
  backgroundColor: "#E8E7E7",
  "& .mainBoxWrap": {
    borderBottom:"1px solid #CBD5E1",
    paddingBottom: 30,
    margin: '0 15px'
  },
  "& .UserInfoTextStyle": {
    color: "#4F5356",
    fontFamily: "Poppins",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 500,
    marginBottom:30
  },
  "& .btn-grid":{
    textAlign:"center"
  },
  "& .profileUploadBtn": {
    color: "#DF4833",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    width: "141px",
    height: "44px",
    cursor: "pointer",
    padding: "10px 16px",
    gap: "8px",
    background: "#FCEDEB",
    textTransform: "none",
    borderRadius: "8px",
    border: 'none'
  },
  "& .profileDeleteBtn": {
    color: "#DF4833",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "none",
    width: "141px",
    height: "44px",
    padding: "10px 16px",
    borderRadius: "8px",
    cursor: "pointer",
    border: "1px solid #DF4833",
    background: "transparent"
  },
  "& .profileTextFielsGrid": {
    marginTop: "20px",
    "@media (max-width: 400px)": {
      marginTop: 0,
    },
  },
  "& .profileBoxWrap": {
    display: "flex",
    gap: "24px",
    "@media (max-width: 400px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  "& .profileDetailBox": {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "center",
    gap: "8px",
    "@media (max-width: 400px)": {
      alignItems: "center",
    },
  },
  "& .profileSaveBtn": {
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    borderRadius: "8px",
    background: "#DF4833",
    textTransform: "none",
    width: "160px",
    height: "44px",
    padding: "10px 16px",
    gap: "8px",
    cursor: "pointer",
    border: 'none'
  },
  "& .profileSaveBtnBox": {
    display: "flex",
    alignItems: "center",
    gap: "24px",
    "@media (max-width: 400px)": {
      alignItems: "center",
      flexDirection: "column",
    },
  },
  "& .profileCancelBtn": {
    color: "#DF4833",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    borderRadius: "8px",
    border: "1px solid #DF4833",
    width: "160px",
    textTransform: "none",
    height: "44px",
    padding: "10px 16px",
    gap: "8px",
    cursor: "pointer",
    background: "transparent"
  },
  "& .profileTextName": {
    color: "#4F5356",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
  },
  "& .profileEmailAndPhoneText": {
    color: "#727578",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .uploadPhotoBtn": {
    color: "#DF4833",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    borderRadius: "8px",
    backgroundColor: "#FCEDEB",
  },
  "& .ProfileImgStyle": {
    height: "100px",
    width: "100px",
    borderRadius: "100%",
    border: "3.03px solid #FCEDEB",
    background: "#FCEDEB",
  },
  "& .ProfileEditStyle": {
    borderRadius: "100%",
    background: "#FFF",
    cursor: 'pointer',
    padding: "6px",
    position: "absolute",
    bottom: 0,
    right: 0
  },
  "& .profileImgsBox": {
    position: "relative"
  },
  "& .profileBtns": {
    display: 'flex',
    alignItems: 'center',
    marginTop: "30px",
    gap: '24px'
  },
  "& .profileTexts": {
    display: 'flex',
    alignItems: 'center',
    gap: '24px'
  },
  "& .FormTextField": {
    backgroundColor: "white",
    borderRadius: "10px",
    "& .MuiOutlinedInput-notchedOutline":{
      borderRadius: "10px",
      top: -6
    },
    '& .Mui-focused fieldset': {
      borderColor: '#4F5356',
    },
    "& .MuiFormHelperText-root.Mui-error": {
      fontFamily: "Poppins",
      fontWeight: 500,
      background: "#E8E7E7",
      color: "#FF1938"
    },
    "& .MuiFormHelperText-contained": {
      margin: 0
    }
  },
  "& .profileTextFields": {
    display: "flex",
    gap: "8px",
    flexDirection: 'column'
  },
  "& .lableInput": {
    color: "#4F5356",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
  },
  "& .FormTextField-placeholder":{
    "&::placeholder":{
      color:'#AEB0B1',
      fontFamily: "Inter",
      fontWeight: 400,
      opacity:1,
      fontSize: 16
    },
  },
  "& .profileTextFieldsLabelBox": {
    display: "flex"
  },
});

const StyledMenu = withStyles({
  list: {
      padding: 4,
      width: 180,
      "& .tooltip-menu":{
          position:"absolute",
          top:-14,
          left:10
      }
  },
  paper: {
      marginTop: 10,
      borderRadius: 8,
      position: "relative",
      width: 188,
      overflow:"visible",
      boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)"
  },
})((props: MenuProps) => (
  <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
      }}
      transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
      }}
      {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
      borderRadius: 8,
      fontFamily: "Inter",
      '& .MuiListItemIcon-root': {
          minWidth: "fit-content",
          marginRight: 8
      },
      '& .MuiListItemIcon-root svg path': {
          fill: "#D97706"
      },
      "& .MuiListItemText-primary": {
          fontFamily: "Inter",
      },
      "&:hover": {
          backgroundColor: "#FCEDEB",
          '& .MuiListItemIcon-root svg path': {
              fill: "#0F172A"
          },
      },
      "& .uploadPhotoStyle": {
        display: "none"
      }
  },
}))(MenuItem);
// Customizable Area End
