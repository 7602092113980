import React from "react";

// Customizable Area Start
import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";
import {
  Box,
  Button,
  Grid,
  Hidden,
  TextField,
  Typography,
  styled,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { barcodeIp, image_bg, redCircle } from "./assets";
// Customizable Area End

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  };

  render() {
    // Customizable Area Start
    const { formValues, formErrors } = this.state;
    return (
      <RegistrationContainerCss>
        <RegistrationCss>
          <Grid container className="signUpHiddenContainer">
            <Hidden smDown>
              <Grid item xs={12} md={6}>
                <Box className="signUpImgMainBox">
                  <img
                    src={image_bg}
                    alt="bgImage"
                    className="signUpImageContainer"
                  />
                  <Box className="signUpImgTextBox">
                    <Box className="signUpImgText">
                      <Box>
                        <img src={redCircle} alt="redCircle" />
                      </Box>
                      <Box>
                        <Typography className="signUpImgMainText">
                          Grow with us
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className="signUpSubText">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Morbi lobortis maximus nunc, ac rhoncus odio
                          congue quis. Sed ac semper orci, eu porttitor lacus.{" "}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Hidden>
            <Grid container className="SignUpFormContainer" item md={6} xs={12}>
              <Grid
                container
                spacing={3}
                item
                xs={11}
                sm={7}
                md={6}
                lg={7}
                xl={8}
              >
                <Grid item xs={12}>
                  <Box className="signUpPrimaryTextBox">
                    <Typography variant="subtitle1" className="signUpWelcome">
                      Welcome to
                    </Typography>
                    <img
                      src={barcodeIp}
                      alt="bgImage"
                      height={36}
                      width={250}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="signUpSecondaryTextBox">
                    <Typography
                      variant="h4"
                      component="h1"
                      className="signUpTypography"
                      data-test-id="heading"
                    >
                      Sign Up
                    </Typography>
                    <Typography variant="body1" className="signUpDetails">
                      Please enter your details.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="signUpFormTextFieldBox">
                    <Box className="lableContainer">
                      <Typography className="inputLable">Name</Typography>
                    </Box>
                    <TextField
                      className="FormTextField"
                      placeholder="Enter Name"
                      variant="outlined"
                      name="name"
                      data-test-id="name"
                      fullWidth
                      required
                      value={formValues.name}
                      onChange={this.handleNameChange}
                      error={Boolean(formErrors.name)}
                      helperText={formErrors.name}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="signUpFormTextFieldBox">
                    <Box className="lableContainer">
                      <Typography className="inputLable">Email</Typography>
                    </Box>
                    <TextField
                      className="FormTextField"
                      placeholder="Enter Email"
                      variant="outlined"
                      name="email"
                      type="email"
                      data-test-id="email"
                      fullWidth
                      required
                      value={formValues.email}
                      onChange={this.handleEmailChange}
                      error={Boolean(formErrors.email)}
                      helperText={formErrors.email}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="signUpFormTextFieldBox">
                    <Box className="lableContainer">
                      <Typography className="inputLable">Message</Typography>
                    </Box>
                    <TextField
                      className="FormTextField"
                      placeholder="Write Message"
                      variant="outlined"
                      name="message"
                      data-test-id="message"
                      fullWidth
                      value={formValues.message}
                      multiline
                      rows={3}
                      onChange={this.handleMessageChange}
                      error={Boolean(formErrors.message)}
                      helperText={formErrors.message}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Button className="signupButton" data-test-id='signupButton' variant="contained" onClick={() => this.handleSignup()}>
                    Sign Up
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Box className="signUpFormLoginBox">
                    <Typography className="signUpAlreadyAcc">
                      Already have an account?
                    </Typography>
                    <Typography className="signUpLogin" id="navigateBtn" onClick={() => this.goToLogInPage()}>Log in</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <CreateAccountDialog
                    open={this.state.createAccount}
                    onClose={this.handleCloseCreateAccount}
                    id="close-createAcc"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                      className: "create-modal",
                      elevation: 0
                    }}
                    BackdropProps={{
                      className: "create-backdrop-modal",
                    }}
                  >
                    <DialogTitle className="create-dailog-HeadingText" id="createAcc-title">{"Connect with Administrator"}</DialogTitle>
                    <DialogContent>
                      <DialogContentText className="create-dailog-contentText1" id="alert-dialog-description">
                      User accounts are approved by the admin. Please contact your administrator <b>admin@barocodeip.com</b> for account setup and login information.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Grid item>
                        <button className="acceptBtn" id="doneBtn" onClick={() => this.handleCloseCreateAccount()}>
                          Done
                        </button>
                      </Grid>
                    </DialogActions>
                  </CreateAccountDialog>
        </RegistrationCss>
      </RegistrationContainerCss>
    );
    // Customizable Area End
  };
}
// Customizable Area Start
const CreateAccountDialog = styled(Dialog)({
  "& .MuiDialogContent-root":{
    padding:"40px",
  },
  "& .MuiDialogTitle-root":{
    borderBottom:"1px solid #CBD5E1",
    padding:"24px 40px",
  },
  "& .MuiDialogActions-root":{
    borderTop:"1px solid #CBD5E1",
    padding:"24px",
  },
  "& .create-modal": {
    borderRadius: 8,
    maxWidth: 600,
    background: '#F5F5F4',
  },
  "& .create-backdrop-modal": {
    backgroundColor: 'rgba(15, 23, 42, 0.4)'
  },
  "& .closeIconStyle": {
    top: 0,
    position: 'absolute',
    padding: 14,
    right: 0,
  },
  "& .acceptBtn": {
    color: "#FFF",
    fontStyle: "normal",
    fontFamily: "Inter",
    width: 150,
    fontWeight: 700,
    borderRadius: "8px",
    background: "#EF4444",
    fontSize: "16px",
    gap: "8px",
    height: 56,
    textTransform: "none",
    padding: "10px 16px",
    border: 'none',
    cursor: "pointer",
  },
  "& .FormTextField": {
    borderRadius: "10px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      top: -6
    },
    "& .MuiInputBase-root": {
      fontFamily: "Inter"
    },
    '& .Mui-focused fieldset': {
      borderColor: '#CBD5E1',
    },
    "& .MuiFormHelperText-contained": {
      margin: 0
    }
  },
  "& .create-dailog-HeadingText": {
    '& .MuiTypography-h6': {
      fontFamily: "Inter",
      color: "#4F5356",
      fontWeight: 700,
      fontSize: "24px",
      "@media (max-width: 524px)": {
        textAlign: "center"
      },
    },
  },
  "& .create-dailog-contentText1": {
    fontFamily: "Inter",
    color: "#4F5356",
    fontWeight: 400,
    fontSize: "16px",
  }
})
const RegistrationContainerCss = styled(Box)({
  "& .MuiContainer-maxWidthLg": {
    maxWidth: "1440px",
  },
});

const RegistrationCss = styled(Box)({
  display: "flex",
  height: "100vh",
  overflow: "auto",
  "& .signUpImageContainer": {
    width: "100%",
    height: "100vh",
  },
  "& .signUpImgMainBox": {
    position: "relative",
  },
  "& .signUpImgText": {
    padding: "100px",
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  "& .signUpImgTextBox": {
    position: "absolute",
    left: "0",
    top: "0",
    gap: "16px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  "& .signUpImgMainText": {
    color: "#FFF",
    display: "flex",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "48px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "64px",
  },
  "& .signUpSubText": {
    color: "#FFF",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  "& .signUpPrimaryTextBox": {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  "& .signUpSecondaryTextBox": {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  "& .signUpFormTextFieldBox": {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  "& .signUpFormLoginBox": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    "@media (max-width: 330px)": {
      flexDirection: "column",
    },
  },
  "& .signUpAlreadyAcc": {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#4F5356",
  },
  "& .signUpLogin": {
    color: "#DF4833",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    cursor: "pointer",
  },
  "& .MuiInputBase-input": {
    fontFamily: "Poppins",
  },
  "& .MuiTypography-root": {
    fontFamily: "Poppins",
  },
  "& .signUpWelcome": {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "28px",
    color: "#4F5356",
  },
  "& .signUpTypography": {
    fontSize: "30px",
    fontWeight: 700,
    fontStyle: "normal",
    lineHeight: "40px",
    letterSpacing: "-0.15px",
    color: "#4F5356",
  },
  "& .signUpDetails": {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#4F5356",
  },
  "& .lableContainer": {
    display: "flex",
  },
  "& .inputLable": {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#4F5356",
  },
  "& .signupButton": {
    background: "#DF4833",
    color: "#FFFFFF",
    fontWeight: 700,
    width: "100%",
    padding: 16,
    borderRadius: "10px",
    textTransform: "none",
    cursor: "pointer",
    "& .MuiButton-root": {
      fontFamily: "Poppins",
    },
  },
  "& .SignUpFormContainer": {
    backgroundColor: "#E8E7E7",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height:"100%",
    overflow: 'scroll',
  },
  "& .signUpHiddenContainer": {
    overflow: "hidden"
  },
  "& .FormTextField": {
    backgroundColor: "white",
    borderRadius: "10px",
    "& .MuiFormHelperText-root.Mui-error":{
      fontFamily: "Poppins",
      fontWeight: 500,
      background: "#E8E7E7",
      color: "#FF1938"
    },
    "& .MuiFormHelperText-contained":{
      margin: 0
    }
  },
});
// Customizable Area End
