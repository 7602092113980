import React from "react";
// Customizable Area Start
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputAdornment, ListItemIcon, ListItemText, Menu, MenuItem, MenuProps, TextField, Theme, Tooltip, Typography, styled, withStyles } from "@material-ui/core";
import Header from "../../../components/src/Header.web";
import UserProfilesRecentSearchController, { Props } from "./UserProfilesRecentSearchController.web";
import { closeIcon, downloadIcon, menuIcon, messageIcon, removeIcon, searchBlackIcon, searchIcon } from "./assets";
import Loader from "../../../components/src/Loader.web";
import moment from "moment";
// Customizable Area End

export default class UserProfilesRecentSearch extends UserProfilesRecentSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <>
      <Loader loading={this.state.loading}/>
        <Header title={""} />
        <UserProfileRecentSearchStyle>
          <Grid container>
            <Grid item xs={12}>
              <Typography id="title" className="recentSearch-Heading">Recent Search</Typography>
            </Grid>
          </Grid>
          <div className="recentSearchMainBox" >
            <Box className="recentSearchBarStyle">
              <Grid container spacing={4}>
                <Grid item xs={12} md={3}>
                  <TextField
                    className="FormTextField"
                    placeholder="Search with Case ID"
                    id="searchId"
                    onChange={(event) => this.handleSearch(event)}
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      className: "FormTextField-placeholder"
                    }}
                    InputProps={{
                      className: "FormTextField-style",
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={searchIcon} alt="searchIcon" />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box className="recentSearchBoxMainContainer">
             {this.state.recentSearchData?.length && !this.state?.recentSearchError ? <Box>
              {(this.state.searchData?.length !==  0 ? this.state?.searchData : this.state?.recentSearchData)?.map((value, index) => (
                <Box className="recentSearchBoxContainer">
                  <Box className="recentSearchMenuIconStyle" id={`open-menu${index}`} onClick={(event: React.MouseEvent<HTMLElement>) => this.menuClickOpen(event,value.id,value.attributes.user_string)}>
                    <img src={menuIcon} alt="menuIcon" />
                  </Box>
                  <StyledMenu
                    id={`customized-close-menu${index}`}
                    anchorEl={this.state.menuShow}
                    keepMounted
                    open={Boolean(this.state.menuShow)}
                    onClose={this.handleMenuClose}
                  >
                    <StyledMenuItem  id="Navigate-landing-page" onClick={() => this.handleNavigatetoLandingPage()}>
                      <ListItemIcon>
                        <img src={searchBlackIcon} alt="profileEdit" />
                      </ListItemIcon>
                      <ListItemText id={`list-item-menu${index}`} primary="Search Again" />
                    </StyledMenuItem>
                    <StyledMenuItem  id="removeListId" onClick={() => this.handleRemoveList()}>
                      <ListItemIcon>
                        <img src={removeIcon} alt="profileEdit" />
                      </ListItemIcon>
                      <ListItemText primary="Remove from the list" />
                    </StyledMenuItem>
                  </StyledMenu>
                  <Grid container item xs={12} className="styledMenuStyle">
                    <Grid container item xs={12}>
                      <Grid item xs={9}>
                        <Box className="recentSearchTextBox">
                          <Box className="recentText2">Case ID : <Box component="span" className="recentSearchTextColor"> {value.attributes?.case_id}</Box></Box>
                          <Box className="recentText3">{value?.attributes?.user_string}</Box>               
                        </Box>
                      </Grid>
                      <Grid container xs={12} className="recentSearchIconContainer">
                        <Grid item>
                          <Box className="recentSearchTextBox">
                            <Box className="recentText4">Searched on <Box component="span" className="recentSearchTextColor">{this.getFormatedDate(value.attributes.created_at)}</Box></Box>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box className="recentSearchIcons" >
                            <LightTooltip title="Connect with an Analyst" arrow placement="bottom-end">
                              <Box className="recentSearchMessageIconStyle">
                                <img src={messageIcon} alt="messageIcon" id={`open-analyst-model${index}`} onClick={() => this.hendleOpenAnalystModel(value)} />
                              </Box>
                            </LightTooltip>
                            
                            <LightTooltip title="Report will be available once the analyst has uploaded it" arrow placement="bottom-end">
                              {value.attributes.download_availability === null ?  <Box className="recentSearchDownloadIconStyle">
                                <img src={downloadIcon} alt="downloadIcon" />
                              </Box> : 
                              <Box onClick={()=> this.handleDownload(value.attributes.support_analysts)} id="downloadPDFId" className="recentSearchIconStyle">
                                <img src={downloadIcon} alt="downloadIcon" />
                              </Box>
                              }
                            </LightTooltip>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              ))}
              </Box> :  <Typography className="textErrorMessage"> No Result Found </Typography>}
              
            </Box>
          </div>
          <AnalystDialog
            open={this.state.openAnalystModel}
            onClose={this.handleAnalystModelClose}
            id="close-analyst-model"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              className: "success-modal",
              elevation: 0
            }}
            BackdropProps={{
              className: "backdrop-modal",
            }}
          >
            <Box className="closeIconStyle" id={`close-analyst-model-click`} onClick={this.handleAnalystModelClose}>
              <IconButton aria-label="close">
                <img src={closeIcon} height={24} width={24} alt="closeIcon" />
              </IconButton>
            </Box>
            <DialogTitle className="dailog-HeadingText" id="alert-dialog-title">{"Connect with an Analyst"}</DialogTitle>
            <DialogContent>
              <DialogContentText className="dailog-contentText1" id="alert-dialog-description">
                {this.state.analystDetails.attributes.user_string}
              </DialogContentText>
              <DialogContentText className="dailog-contentText2" id="alert-dialog-description">
                User Name - {this.state.email}
              </DialogContentText>
              <DialogContentText className="dailog-contentText3" id="alert-dialog-description">
                Searched on {moment(this.state.analystDetails.attributes.created_at).format("MMMM DD [at] h:mm A")}
              </DialogContentText>
              <Grid item xs={12}>
                <TextField
                  className="FormTextField"
                  placeholder="Type Message"
                  variant="outlined"
                  name="message"
                  value={this.state.recentMessage}
                  id="recentMessageId"
                  onChange={(event) => this.handleChange(event)}
                  fullWidth
                  multiline
                  rows={3}
                />
              </Grid>
              <Typography className="error-msg">{this.state.recentMessageError}</Typography>
              <Grid item xs={12}>
                <button className="submitBtn" id="recentSearchMsgId" onClick={() => this.handleSubmit()}>
                  Submit
                </button>
              </Grid>
            </DialogContent>
          </AnalystDialog>

          <DeleteAccountDialog
            open={this.state.openRemoveList}
            onClose={this.handleRemoveListClose}
            id="terms-onClose-model"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              className: "delete-modal",
              elevation: 0
            }}
            BackdropProps={{
              className: "backdrop-modal-delete",
            }}
          >
            <Box className="closeIconStyle">
              <IconButton aria-label="close">
                <img src={closeIcon} height={24} width={24} alt="closeIcon" id="closeIcon" onClick={() => this.handleRemoveListClose()} />
              </IconButton>
            </Box>
            <DialogTitle className="title-model-delete" id="delete-dialog-title">{"Are you sure you want to remove this search from the list"}</DialogTitle>
            <DialogActions>
              <Grid container spacing={3} justifyContent="flex-end">
                <Grid item className="button-conainer">
                  <button className="cancleBtn" id="cancleBtn" onClick={() => this.handleRemoveListClose()}>
                    Cancel
                  </button>
                </Grid>
                <Grid item className="button-conainer">
                  <button className={"deleteBtn"} id="confirmBtn" onClick={() => this.removeListApi()}>
                    Remove
                  </button>
                </Grid>
              </Grid>
            </DialogActions>
          </DeleteAccountDialog>
        </UserProfileRecentSearchStyle >
      </>
      // Customizable Area End
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const UserProfileRecentSearchStyle = styled(Box)({
  padding: '2% 5%',
  backgroundColor: "#E8E7E7",
  "& .mainBox": {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  "& .recentSearch-Heading": {
    color: "#4F5356",
    fontFamily: "Poppins",
    fontSize: 30,
    fontWeight: 500,
    marginBottom: 30
  },
  "& .recentSearchBarStyle": {
    marginBottom: 32
  },
  "& .recentSearchMainBox": {
    borderRadius: "8px",
    background: "#F5F5F4",
    padding: "24px",
  },
  "& .recentSearchBoxContainer": {
    position: "relative",
    padding: '24px',
    borderRadius: 5,
    border: "1px solid rgba(174, 176, 177, 0.50)",
    background: '#F5F5F4'
  },
  "& .recentSearchBoxMainContainer": {
    display: "flex",
    flexDirection: "column",
    gap: 32
  },
  "& .recentSearch-ImageBox": {
    height: 80,
    width: 80
  },
  "& .recentSearch-Image": {
    width: "100%",
    height: "100%",
    objectFit: "cover"
  },
  "& .recentSearchTextBox": {
    paddingLeft: 16,
    "@media (max-width: 1000px)": {
      paddingLeft: 46,
    },
    "@media (max-width: 600px)": {
      paddingLeft: 0,
    },
  },
  "& .recentSearchIconContainer": {
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 600px)": {
      gap: 24
    },
  },
  "& .recentSearchIcons": {
    display: 'flex',
    alignItems: "center",
    gap: 5
  },
  "& .recentSearchMessageIconStyle": {
    display: 'flex',
    height: '44px',
    width: '44px',
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    border: '1px solid  #AEB0B1',
    background: '#EDEEEE',
  },
  "& .recentSearchDownloadIconStyle": {
    display: 'flex',
    height: '44px',
    width: '44px',
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    border: '1px solid  #AEB0B1',
    background: '#CBD5E1',
  },
  "& .recentSearchIconStyle" : {
    display: 'flex',
    height: '44px',
    width: '44px',
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    border: '1px solid  #AEB0B1',
    background: '#EDEEEE',
  },
  "& .recentText1": {
    color: "#4F5356",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 500,
  },
  "& .recentText2": {
    color: "#4F5356",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 400,
    margin: "5px 0"
  },
  "& .recentText3": {
    color: "#898C8E",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    paddingRight: 70,
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "@media (max-width: 600px)": {
      paddingRight: 0
    },
  },
  "& .recentText4": {
    color: "#4F5356",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .recentSearchMenuIconStyle": {
    position: 'absolute',
    top: 25,
    right: 25,
    cursor: "pointer",
  },
  "& .recentSearchTextColor": {
    color: "#DF4833",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 600,
  },
  "& .FormTextField": {
    backgroundColor: "white",
    borderRadius: "8px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      top: -6
    },
    '& .Mui-focused fieldset': {
      borderColor: '#4F5356',
    },
    "& .MuiFormHelperText-root.Mui-error": {
      fontFamily: "Poppins",
      fontWeight: 500,
      background: "#E8E7E7",
      color: "#FF1938"
    },
    "& .MuiFormHelperText-contained": {
      margin: 0
    }
  },
  "& .FormTextField-placeholder": {
    "&::placeholder": {
      color: '#AEB0B1',
      fontFamily: "Inter",
      fontWeight: 400,
      opacity: 1,
      fontSize: 16
    },
  },
  "& .FormTextField-style": {
    height: 44
  },
  "& .styledMenuStyle": {
    paddingBottom: 30,
    borderBottom: "1px solid #AEB0B1",
    "@media (max-width: 600px)": {
      display: "flex",
      gap: 24,
      flexDirection: "column"
    },
  },
  "& .textErrorMessage":{
    textAlign:"center",
    fontSize:"20px"
  },
 
});

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#4F5356',
    boxShadow: "none",
    fontSize: 12,
    fontFamily: 'Inter',
    fontWeight: 400,
    borderRadius: 8,
    padding: 12,
    minWidth: "max-content",
  },
  arrow: {
    color: theme.palette.common.white,
  }
}))(Tooltip);

const StyledMenu = withStyles({
  list: {
    padding: 4,
    width: 210,
    "& .tooltip-menu": {
      position: "absolute",
      top: -14,
      left: 10
    },
  },
  paper: {
    marginTop: 10,
    borderRadius: 8,
    position: "relative",
    width: 230,
    overflow: "visible",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)"
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    borderRadius: 8,
    fontFamily: "Inter",
    width: "222px",
    '& .MuiListItemIcon-root': {
      minWidth: "fit-content",
      marginRight: 8
    },
    '& .MuiListItemIcon-root svg path': {
      fill: "#D97706"
    },
    "& .MuiListItemText-primary": {
      fontFamily: "Inter",
    },
    "&:hover": {
      backgroundColor: "#FCEDEB",
      '& .MuiListItemIcon-root svg path': {
        fill: "#0F172A"
      },
    },
  },
}))(MenuItem);

const AnalystDialog = styled(Dialog)({
  "& .success-modal": {
    background: '#F5F5F4',
    padding: 24,
    borderRadius: 8,
    maxWidth: 851,
    width: "100%"
  },
  "& .closeIconStyle": {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 14
  },
  "& .submitBtn": {
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    borderRadius: "8px",
    background: "#EF4444",
    textTransform: "none",
    width: "160px",
    height: "44px",
    padding: "10px 16px",
    gap: "8px",
    cursor: "pointer",
    border: 'none',
    marginTop: 24,
    marginRight:10
  },
  "& .FormTextField": {
    borderRadius: "10px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      top: -6
    },
    "& .MuiInputBase-root": {
      fontFamily: "Inter"
    },
    '& .Mui-focused fieldset': {
      borderColor: '#CBD5E1',
    },
    "& .MuiFormHelperText-contained": {
      margin: 0
    }
  },
  "& .dailog-HeadingText": {
    '& .MuiTypography-h6': {
      marginTop: 24,
      color: "#4F5356",
      fontFamily: "Inter",
      fontSize: "30px",
      fontWeight: 700,
      "@media (max-width: 524px)": {
        textAlign: "center"
      },
    },
  },
  "& .dailog-contentText1": {
    color: "#4F5356",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 400,
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .dailog-contentText2": {
    color: "#4F5356",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
  },
  "& .dailog-contentText3": {
    color: "#4F5356",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
  },
  "& .error-msg": {
    color: "red",
    marginTop: "10px"
  }
})

const DeleteAccountDialog = styled(Dialog)({
  "& .MuiDialogTitle-root": {
    borderBottom: "1px solid #CBD5E1",
    padding: "70px 40px",
  },
  "& .MuiDialogActions-root": {
    borderTop: "1px solid #CBD5E1",
    padding: "24px",
  },
  "& .delete-modal": {
    borderRadius: 8,
    maxWidth: 600,
    background: '#F5F5F4',
  },
  "& .backdrop-modal-delete": {
    backgroundColor: 'rgba(15, 23, 42, 0.4)'
  },
  "& .closeIconStyle": {
    top: 0,
    right: 0,
    position: 'absolute',
  },
  "& .button-conainer": {
    "@media (max-width: 435px)": {
      width:"100%"
    },
  },
  "& .deleteBtn": {
    fontWeight: 700,
    fontStyle: "normal",
    color: "#FFF",
    width: 150,
    borderRadius: "8px",
    fontFamily: "Inter",
    height: 56,
    background: "#EF4444",
    gap: "8px",
    padding: "10px 16px",
    textTransform: "none",
    cursor: "pointer",
    fontSize: "16px",
    border: 'none',
    "@media (max-width: 435px)": {
      width:"100%"
    },
  },
  "& .cancleBtn": {
    color: "#DF4833",
    fontSize: "16px",
    fontFamily: "Inter",
    border: 'none',
    fontWeight: 700,
    textTransform: "none",
    height: 56,
    cursor: "pointer",
    width: 150,
    fontStyle: "normal",
    padding: "10px 16px",
    borderRadius: "8px",
    background: "#FCEDEB",
    gap: "8px",
    "@media (max-width: 435px)": {
      width:"100%"
    },
  },
  "& .title-model-delete":{
    '& .MuiTypography-h6':{
      fontFamily: 'Poppins',
      color: '#4F5356',
      fontWeight: 500,
      fontSize: 22,
      "@media (max-width: 760px)": {
        textAlign:"center"
      },
    }
  },
 
})
// Customizable Area End
