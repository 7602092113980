export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const showEye = require("../assets/show-eye.svg");
export const hideEye = require("../assets/hide-eye.svg");
export const image_bg = require("../assets/image_bg.png");
export const barcodeIp = require("../assets/barcodeIp.svg");
export const redCircle = require("../assets/redCircle.svg");
export const checkedIcon = require("../assets/checkedIcon.svg");
export const uncheckedIcon = require("../assets/uncheckedIcon.svg");