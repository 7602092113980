import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
interface LandScapes {
  text: string;
};

interface Citations {
  publication_number: string;
  primary_language: string;
  priority_date: string;
  publication_date: string;
  assignee_original: string;
  title: string;
  patent_id: string;
  serpapi_link: string;
}

interface PatentCitations {
  original: Citations[];
}

interface CitationsBy {
  family_to_family: Citations[];
  original:Citations[]
}

interface LegalEvents {
  date: string;
  code: string;
  title: string;
  attributes: {
    label: string;
    value: string;
  }[]
}

interface WorldwideApplications {
  [key: string]: {
    filing_date: string;
    country_code: string;
    application_number: string;
    document_id: string;
    legal_status_cat: string;
    legal_status: string;
  }[]
}

interface Events {
  date: string;
  title: string;
  type: string;
  assignee_search: string;
  description: string[];
  document_id: string;
}

interface Classifications {
  code: string;
    description: string;
    leaf: boolean;
    first_code: boolean;
    is_cpc: boolean;
}

interface ParentApplication {
  application_number: string;
  relation_type: string;
  representative_publication: string;
  primary_language: string;
  priority_date: string;
  filing_date: string;
  title: string;
}

interface ApplicationClaim {
  application_number: string;
  representative_publication: string;
  primary_language: string;
  priority_date: string;
  filing_date: string;
  title: string;
}

interface SimilarDocuments {
  is_patent: string;
  publication_number: string;
  primary_language: string;
  publication_date: string;
  title: string;
  patent_id: string;
  serpapi_link: string;
}
interface Details {
  position: number;
  rank: number;
  description: string;
  patent_id: string;
  serpapi_link: string;
  title: string;
  abstract: string;
  priority_date: string;
  filing_date: string;
  grant_date: string;
  publication_date: string;
  inventor: { name: string }[];
  assignee: string[];
  publication_number: string;
  language: string;
  pdf: string;
  application_number: string;
  thumbnail?: string;
  images: string[];
  classifications: Classifications[];
  events: Events[];
  claims: string[];
  legal_events: LegalEvents[];
  worldwide_applications: WorldwideApplications;
  patent_citations: PatentCitations;
  cited_by: CitationsBy;
  parent_applications: ParentApplication[];
  priority_applications: ParentApplication[];
  applications_claiming_priority: ApplicationClaim[];
  country_status: { [countryCode: string]: string };
  similar_documents: SimilarDocuments[];
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  landscapesArr: LandScapes[];
  tabs: { name: string }[];
  tabsName: string;
  loading: boolean;
  detailsData: Details | null;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageViewReportClaimsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetDetailsCallId: string = "";
  apiGetPatentDetailsCallId: string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      landscapesArr: [
        { text: "Engineering & Computer Science" },
        { text: "Computer networks & Wireless Communication" },
        { text: "Signal Processing" },
        { text: "Multimedia" },
        { text: "Mobile Radio Communication System" },
      ],
      tabs: [
        { name: "Description" },
        { name: "Claims" },
        { name: "Information" }
      ],
      tabsName: "Description",
      loading: false,
      detailsData: null,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.apiGetDetailsCallId) {
      const expireToken = responseJson.errors?.[0]?.token
      if (expireToken) {
        this.navigateToLogInPage()
      }
      if (responseJson) {
        this.setState({
          detailsData: responseJson,
          loading: false
        })
      } else {
        this.setState({
          loading: false
        })
      }
    } 
    if(apiRequestCallId === this.apiGetPatentDetailsCallId) {
      if(responseJson) {
        this.setState({detailsData:responseJson,loading: false})
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const authToken = await getStorageData("token")
    if(!authToken){
      this.navigateToLogInPage()
    }

    const BackToDetails = await getStorageData("BackToDetails")
    if(BackToDetails) {
      this.getPatentDetailsAPI()
    } else {
      const publicationId = this.props.navigation.getParam("id")
      setStorageData("publicationId",publicationId)
      if(publicationId !== "N"){
        this.getDetailsAPI(publicationId)
      }
    }
  };

  getDetailsAPI = async (publicationId: string) => {
    this.setState({ loading: true })
    const header = {
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDetailsAPIEndPoint + publicationId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPatentDetailsAPI = async () => {
    this.setState({ loading: true })
    const header = {
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPatentDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPatentDetailsAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  goBackToKeyFeaturePage = async () => {
    removeStorageData("BackToDetails")
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "KeyFeatureLandingPageWeb");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  navigateToLogInPage = async () => {
    const messageToLogin: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    messageToLogin.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );
    messageToLogin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    messageToLogin.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(messageToLogin);
  };

  onClickTabChange = (value: string) => {
    this.setState({ tabsName: value })
  };

  goToShowMappingPage = async () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPageShowMapping"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  // Customizable Area End
}
